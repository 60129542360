<template>
  <v-card class="mb-15 elevation-10 mx-15" width="350" height="420">
    <div v-if="item.discount != 0" class="bookmark">
      <div class="tag" :style="{ 'background-color': item.tagcolor }">
        <div class="fz-2 ">ประหยัดไป</div>
        <div class="text-h5 ">{{ item.discount }}.-</div>
        <div
          class="t1"
          :style="{ 'border-top': '50px solid ' + item.tagcolor }"
        ></div>
        <div
          class="t2"
          :style="{ 'border-top': '50px solid ' + item.tagcolor }"
        ></div>
      </div>
    </div>

    <v-card-text>
      <v-row>
        <v-col
          cols="12"
          class="text-center text-h5 py-1 mt-3 primary--text font-weight-bold"
          >{{ item.name }}</v-col
        >
        <v-col
          v-if="item.discount != 0"
          cols="12"
          class="text-center fz-4 py-0 red--text font-weight-bold"
        >
          ใช้ฟรี 2 เดือน</v-col
        >

        <v-col cols="12" class="text-center py-1 mb-3"
          >ใช้งานสูงสุด {{ item.bills }} ใบเสร็จ</v-col
        >
        <v-col v-if="item.discount === 0" cols="12" class=" pb-2"> </v-col>
        <!-- <v-col cols="12" class="text-center py-0 fz-1">{{smallTitle}}</v-col> -->
        <!-- <div class="alert"> -->
        <v-alert
          v-if="item.discount === 0"
          outlined
          rounded="0"
          width="330"
          height="130"
          elevation="5"
          class="mx-auto col-12"
          :style="{
            padding: '3px solid ' + item.tagcolor,
            color: item.tagcolor
          }"
        >
          <v-col cols="12" class="pt-0">
            <div
              v-if="item.product_type != 2"
              class="text-center mr-16 pr-16 black--text"
            >
              จ่ายเพียง
            </div>
            <div class="text-center text-h2 black--text">
              {{
                item.product_type == 2
                  ? ((item.price - item.discount) / (12 * item.period)) % 1 == 0
                    ? (item.price - item.discount) / (12 * item.period)
                    : (
                        (item.price - item.discount) /
                        (12 * item.period)
                      ).toFixed(0)
                  : ((item.price - item.discount) / item.period) % 1 == 0
                  ? (item.price - item.discount) / item.period
                  : ((item.price - item.discount) / item.period).toFixed(0)
              }}
            </div>
            <div class="text-center ml-16 pl-10 black--text">บาท/เดือน</div>
          </v-col>
        </v-alert>
        <!-- </div> -->
        <v-alert
          v-if="item.discount != 0"
          rounded="0"
          class="mx-auto col-12 "
          height="130"
          elevation="5"
          :style="{
            padding: '3px solid ' + item.tagcolor,
            background: item.tagcolor,
            color: item.tagcolor
          }"
        >
          <v-col cols="12" class="pt-0">
            <div
              v-if="item.product_type == 2"
              class="text-center mr-16 pr-16 white--text"
            >
              ตกแล้วเดือนละ
            </div>

            <div class="text-center text-h2 white--text">
              {{
                item.product_type == 2
                  ? ((item.price - item.discount) / (12 * item.period)) % 1 == 0
                    ? (item.price - item.discount) / (12 * item.period)
                    : (
                        (item.price - item.discount) /
                        (12 * item.period)
                      ).toFixed(0)
                  : ((item.price - item.discount) / item.period) % 1 == 0
                  ? (item.price - item.discount) / item.period
                  : ((item.price - item.discount) / item.period).toFixed(0)
              }}
            </div>
            <div class="text-center ml-16 pl-10 white--text">บาท/เดือน</div>
          </v-col>
        </v-alert>
        <v-col cols="12" class="text-center py-0 font-weight-bold fz-3">
          {{ item.product_type == 0 ? 'เรียกเก็บเงินครั้งเดียว' : '' }}
          {{ item.product_type == 1 ? 'เรียกเก็บเงินรายเดือน' : '' }}
          {{ item.product_type == 2 ? 'เรียกเก็บเงินรายปี' : '' }}
        </v-col>
        <v-col
          v-if="item.discount == 0"
          cols="12"
          class="text-center py-0 fz-3 mb-2"
        >
          ชำระเงินทุกเดือน เดือนละ{{ item.price - item.discount }}
          {{ item.product_type == 0 ? 'บาท' : '' }}
          {{ item.product_type == 1 ? 'บาท/เดือน' : '' }}
          {{ item.product_type == 2 ? 'บาท/ปี' : '' }}
        </v-col>
        <v-col
          v-if="item.discount != 0"
          cols="12"
          class="text-center py-0 fz-3"
        >
          ชำระเงินเป็นรายปี ปีละ {{ item.price - item.discount }}
          {{ item.product_type == 0 ? 'บาท' : '' }}
          {{ item.product_type == 1 ? 'บาท/เดือน' : '' }}
          {{ item.product_type == 2 ? 'บาท/ปี' : '' }}
          &nbsp;<v-icon class="mb-1"> mdi-alert-circle</v-icon>
        </v-col>
        <v-col v-if="item.discount === 0" cols="12" class="text-center mt-2">
          <v-btn
            :style="{
              border: '3px solid ' + item.tagcolor,
              color: item.tagcolor
            }"
            outlined
            rounded
            @click="pay()"
            >สมัครแพ็คเกจ</v-btn
          >
        </v-col>

        <v-col v-if="item.discount != 0" cols="12" class="text-center mt-2">
          <v-btn
            class="white--text"
            rounded
            @click="pay()"
            :style="{
              border: '3px solid ' + item.tagcolor,
              background: item.tagcolor,
              color: item.tagcolor
            }"
            >สมัครแพ็คเกจ</v-btn
          >
        </v-col>
        <v-col cols="12" class="text-right pr-5"
          >*ราคานี้ยังไม่รวมภาษีมูลค่าเพิ่ม</v-col
        >
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: null
    }
  },
  methods: {
    pay() {
      this.$router.push({
        name: `package-your-pay`,
        params: { id: this.item._id }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$bookmark-color: rgb(48, 176, 255);
.v-card {
  border-radius: 30px;
}
.v-alert {
  outline-style: solid;
  outline-width: 4px;
  position: relative;
  color: #29b6f6;
}
.bookmark {
  z-index: 1;

  .tag {
    position: absolute;
    right: 17px;
    top: 0px;
    width: 70px;
    text-align: center;
    color: white;
    background-color: $bookmark-color;
    padding: 5px 0px;
    margin-right: 5px;

    .t1 {
      position: absolute;
      bottom: -40px;
      left: 0px;
      width: 0;
      height: 0;
      border-top: 50px solid $bookmark-color;
      border-right: 50px solid transparent;
    }

    .t2 {
      position: absolute;
      bottom: -40px;
      right: 0px;
      width: 0;
      height: 0;
      border-top: 50px solid $bookmark-color;
      border-left: 50px solid transparent;
    }
  }
}
</style>
