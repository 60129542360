<template>
  <div>
    <v-card class="py-8">
      <v-card-title class="justify-center text-h4 pb-10 primary--text">ข้อมูลบิลใบเสร็จ</v-card-title>
      <v-card-text class="popup-background">

        <v-row justify="center">
          <v-col cols="4" class="mt-5">
            <v-text-field 
              name="name_for_receipt" 
              label="ชื่อสำหรับออกใบเสร็จ"
              v-model="temp_name_for_receipt"
              outlined
              dense
            />
            <v-text-field 
              name="taxpayer_number"
              label="เลขประจำตัวผู้เสียภาษี"
              v-model="temp_taxpayer_number"
              outlined
              dense
            />
            <v-text-field
              name="company_branch"
              label="สาขา"
              placeholder="สำนักงานใหญ่"
              v-model="temp_company_branch"
              outlined
              dense
            />
            <v-text-field
              name="address"
              label="ที่อยู่"
              outlined dense
              autocomplete="off"
              :value="`${temp_address.address} ${temp_address.sub_district} ${temp_address.district} ${temp_address.province} ${temp_address.postal_code}`"
              @click="
                (dialogAddressData = temp_address),
                (dialogAddress = true)
              "
              hide-details
            />
          </v-col>
        </v-row>
      </v-card-text>

      <v-col class="text-right py-10">
        <v-card-actions class="py-5">
          <v-spacer/>
          <v-btn class="px-5 mr-10" color="grey" outlined @click="$emit('onClose')">ยกเลิก</v-btn>
          <v-btn class="px-10 mr-15" color="primary" @click="save()">บันทึกข้อมูล</v-btn>
        </v-card-actions>
      </v-col>

    </v-card>

    <v-dialog persistent scrollable max-width="1200" v-model="dialogAddress">
      <AddressDetail
        :item="dialogAddressData"
        @onClose="dialogAddress = false"
        @onSubmit="onSubmitAddress"
      />
    </v-dialog>
  </div>
</template>

<script>
import AddressDetail from "@/components/Address/AddressDetail.vue";

export default {
  components: {
    AddressDetail,
  },
  props: {
    name_for_receipt: {
      type: String,
      default: null,
    },
    taxpayer_number: {
      type: String,
      default: null,
    },
    company_branch: {
      type: String,
      default: null,
    },
    address: {
      type: Object,
      default: {},
    },    
  },
  data: () => ({
    dialogAddress: false,
    dialogAddressData: {
      address: "",
      district: "",
      postal_code: "",
      province: "",
      sub_district: "",
    },
    temp_name_for_receipt: "",
    temp_taxpayer_number: "",
    temp_company_branch: "",
    temp_address: {
      address: "",
      district: "",
      postal_code: "",
      province: "",
      sub_district: "",
    },
  }),
  created() {
    this.temp_name_for_receipt = this.name_for_receipt
    this.temp_taxpayer_number = this.taxpayer_number
    this.temp_company_branch = this.company_branch
    this.temp_address = this.address
  },
  methods: {
    onSubmitAddress(val) {
      this.temp_address = val;
      this.dialogAddress = false;
    },
    async save() {
      this.loading = true

      let sendForm = {
        name_for_receipt: this.temp_name_for_receipt,
        taxpayer_number: this.temp_taxpayer_number,
        company_branch: this.temp_company_branch,
        address: this.temp_address,
      }
      let body = {
        token: this.$jwt.sign(sendForm, this.$privateKey, {noTimestamp: true}),
      };
      await this.$axios.post(`${this.$baseUrl}/package/edit_receipt_data`, body)
        .then( async (res) => {
          this.$alertSuccess({ title: res.status });
        })
        .catch((err) => {
          console.log("err", err);
          this.$alertServerError({ title: err.error_message });
        });
      this.loading = false;
      this.$emit('onClose')
    },
  },
}
</script>